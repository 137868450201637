import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { MdWarning } from "react-icons/md";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";
import useTemplate from "../hooks/useTemplate";

const Summary = () => {
  let history = useHistory();
  const {
    state: {
      data: { selection, finance, partExchange, details, summary },
    },
    dispatch,
  } = useContext(Context);

  const { emailAddress, branchToEmail, branchFromEmail } = details;
  const { preferences, models } = selection;
  const { budgets, mileages, deposit } = finance;
  const { make, model, registration, mileage, transmission, fuel, colour, currentMileage, conditions, comments, contractStartDate, contractEndDate, monthlyPayment } = partExchange;
  const [isLoading, setIsLoading] = useState(false);

  const [getTemplate] = useTemplate();

  const api_key = process.env.REACT_APP_MAILGUN_API_KEY;
  const domain = "bigmarketing.co.uk";

  const mailgun = require("mailgun-js")({ apiKey: api_key, domain: domain });

  const [selectionFields, setSelectionFields] = useState(JSON.parse(localStorage.getItem("selection")) || selection);
  const [financeFields, setFinanceFields] = useState(JSON.parse(localStorage.getItem("finance")) || finance);
  const [partExchangefields, setPartExchangeFields] = useState(JSON.parse(localStorage.getItem("partExchange")) || partExchange);
  const [detailsfields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);
  const [summaryFields, setSummaryFields] = useState(JSON.parse(localStorage.getItem("summary")) || summary);
  const [mandatoryFields, setMandatoryFields] = useState({ contactMethods: false, contactTimes: false, contactDays: false });
  const [showErrors, setShowErrors] = useState(false);

  const preferencesArray = [];
  const modelsArray = [];

  preferences.map((preference) => {
    if (preference.selected) {
      preferencesArray.push(preference.title);
    }
    return undefined;
  });

  models.map((model) => {
    if (model.selected) {
      modelsArray.push(model.title);
    }
    return undefined;
  });

  const navigateNext = async (slug) => {
    const { contactMethods, contactTimes, contactDays } = mandatoryFields;
    if (contactMethods && contactTimes && contactDays) {
      // Set the layout to completed and then

      // "o:deliverytime": "Fri, 6 Nov 2020, 11:14:47 -0000",
      // const currentTime = moment().utc().format("ddd, D MMM YYYY, H:mm:ss ZZ");
      const futureTime = moment().add(1, "days").set({ hour: "09", minute: "01", seconds: "00" }).format("ddd, D MMM YYYY, H:mm:ss ZZ");
      //const futureTime = moment().add(1, "minutes").format("ddd, D MMM YYYY, H:mm:ss ZZ");
      //const futureTime = moment().set({ hour: "15", minute: "30", seconds: "00" }).format("ddd, D MMM YYYY, H:mm:ss ZZ");
      console.log(futureTime);
      const dealerData = {
        from: `Volkswagen Finance Loyalty Programme <${branchFromEmail}>`,
        to: branchToEmail,
        bcc: "web.copy@bigmarketing.co.uk, andy.overton@bigmarketing.co.uk, nick.hill@bigmarketing.co.uk",
        //to: "mahmoud.el-mahmoud@bigmarketing.co.uk, tim.wait@bigmarketing.co.uk, nick.hill@bigmarketing.co.uk",
        //to: "mahmoud.el-mahmoud@bigmarketing.co.uk, tim.wait@bigmarketing.co.uk",
        subject: "Booking Confirmation - Volkswagen Finance Loyalty Programme",
        html: getTemplate().dealerTemplate,
      };

      const customerData = {
        from: `Volkswagen Finance Loyalty Programme <${branchFromEmail}>`,
        to: emailAddress,
        bcc: `${branchToEmail}, web.copy@bigmarketing.co.uk, andy.overton@bigmarketing.co.uk, nick.hill@bigmarketing.co.uk`,
        //to: "mahmoud.el-mahmoud@bigmarketing.co.uk, tim.wait@bigmarketing.co.uk, nick.hill@bigmarketing.co.uk",
        //to: "mahmoud.el-mahmoud@bigmarketing.co.uk, tim.wait@bigmarketing.co.uk",
        subject: "Booking Confirmation - Volkswagen Finance Loyalty Programme",
        html: getTemplate().customerTemplate,
        "o:deliverytime": futureTime,
      };

      setIsLoading(true);

      mailgun
        .messages()
        .send(dealerData)
        .then(
          function (data) {
            mailgun
              .messages()
              .send(customerData)
              .then(
                function (data) {
                  console.log(data);
                  setIsLoading(false);
                  dispatch({ type: "SET_LAYOUT_VISITED", slug: "summary" });
                  history.push(slug);
                },
                function (err) {
                  setIsLoading(false);
                  console.log(err);
                }
              );
          },
          function (err) {
            console.log(err);
            setIsLoading(false);
          }
        );
    } else {
      //set page error  to true
      setShowErrors(true);
    }
  };

  const navigateAmend = (slug) => {
    history.push(slug);
  };

  const updateContactMethods = (title) => {
    dispatch({ type: "UPDATE_SUMMARY_CONTACTMETHOD", title });
  };

  const updateContactTimes = (title) => {
    dispatch({ type: "UPDATE_SUMMARY_CONTACTTIME", title });
  };

  const updateContactDays = (title) => {
    dispatch({ type: "UPDATE_SUMMARY_CONTACTDAY", title });
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const selection = localStorage.getItem("selection");
    const finance = localStorage.getItem("finance");
    const partExchange = localStorage.getItem("partExchange");
    const details = localStorage.getItem("details");
    const summary = localStorage.getItem("summary");

    if (selection) {
      dispatch({ type: "UPDATE_ALL_SELECTIONS", data: JSON.parse(selection) });
      setDetailsFields(selection);
    }

    if (finance) {
      dispatch({ type: "UPDATE_ALL_FINANCES", data: JSON.parse(finance) });
      setDetailsFields(finance);
    }

    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    if (partExchange) {
      dispatch({ type: "UPDATE_ALL_PARTEXCHANGE", data: JSON.parse(partExchange) });
      setDetailsFields(partExchange);
    }

    if (summary) {
      dispatch({ type: "UPDATE_ALL_SUMMARY", data: JSON.parse(summary) });
      setSummaryFields(summary);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    const { contactMethods, contactTimes, contactDays } = mandatoryFields;

    setSelectionFields(selection);
    localStorage.setItem("selection", JSON.stringify(selection));

    setFinanceFields(finance);
    localStorage.setItem("finance", JSON.stringify(finance));

    setPartExchangeFields(partExchange);
    localStorage.setItem("partExchange", JSON.stringify(partExchange));

    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));

    setSummaryFields(summary);
    localStorage.setItem("summary", JSON.stringify(summary));

    if (contactMethods && contactTimes && contactDays) {
      // Set the layout to completed and then
      setShowErrors(false);
    }
  }, [selection, finance, partExchange, details, summary, mandatoryFields]);

  // This useEffect will check whether any of the fields has been selected and set the button visibility
  useEffect(() => {
    const contactMethodIsEntered = summaryFields.contactMethods.some((element) => {
      return element.selected === true;
    });

    const contactTimeIsEntered = summaryFields.contactTimes.some((element) => {
      return element.selected === true;
    });

    const contactDayIsEntered = summaryFields.contactDays.some((element) => {
      return element.selected === true;
    });

    setMandatoryFields((prevState) => {
      return { ...prevState, contactMethods: contactMethodIsEntered, contactTimes: contactTimeIsEntered, contactDays: contactDayIsEntered };
    });
  }, [summaryFields]);

  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">My summary</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">
            <img className="img-fluid" alt="event logo" src={EventImage} />
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-3">
          <div className="col-12 col-md-6">
            <div className="row text-center text-md-left">
              <div className="col-12">
                <h5>Here are the details of the vehicle(s) you are interested in.</h5>
              </div>
              <div className="col-12">
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>{preferencesArray.length > 1 ? <span>Vehicle preferences:</span> : <span>Vehicle preference:</span>}</strong> <span>{preferencesArray.join(", ")}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>{modelsArray.length > 1 ? <span>Models:</span> : <span>Model:</span>}</strong> <span>{modelsArray.join(", ")}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 justify-content-center justify-content-md-start">
                  <div className="col-12 col-lg-6">
                    <div className="btn btn-primary" onClick={() => navigateAmend("selection")}>
                      Amend
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <h5>Here are the details of your finance options.</h5>
              </div>
              <div className="col-12">
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Monthly budget:</strong>
                      <span>
                        {budgets.map((budget) => {
                          if (budget.selected) {
                            return <span key={budget.title}> {budget.title}</span>;
                          } else {
                            return null;
                          }
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Initial deposit:</strong> <span>£{deposit}</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Average annual mileage:</strong>
                      <span>
                        {mileages.map((mileage) => {
                          if (mileage.selected) {
                            return <span key={mileage.title}> {mileage.title}</span>;
                          } else {
                            return null;
                          }
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 justify-content-center justify-content-md-start">
                  <div className="col-12 col-md-6">
                    <div className="btn btn-primary" onClick={() => navigateAmend("finance")}>
                      Amend
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <h5>Here are the details of your current vehicle.</h5>
              </div>
              <div className="col-12">
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Make:</strong> <span>{make}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Model:</strong> <span>{model}</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Registration:</strong> <span>{registration}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Current mileage:</strong> <span>{currentMileage}</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Transmission:</strong> <span>{transmission}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Fuel:</strong> <span>{fuel}</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Colour:</strong> <span>{colour}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Car condition:</strong>{" "}
                      <span>
                        {" "}
                        {conditions.map((condition) => {
                          if (condition.selected) {
                            return <span key={condition.title}> {condition.title}</span>;
                          } else {
                            return null;
                          }
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Finance contract start date:</strong> <span>{contractStartDate}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Finance contract renewal date:</strong> <span>{contractEndDate}</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-6">
                    <div className="p">
                      <strong>Current monthly payment:</strong> <span>£{monthlyPayment}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-8">
                    <div className="p">
                      <strong>Comments:</strong> {comments.length > 0 ? <span>{comments}</span> : <span>None specified</span>}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 justify-content-center justify-content-md-start">
                  <div className="col-12 col-md-6">
                    <div className="btn btn-primary" onClick={() => navigateAmend("part-exchange")}>
                      <div className="p">Amend</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-5 mt-md-0">
            <div className="row">
              <div className="col-12 mb-3">
                <h3 className="page-sub-title">How would you like us to contact you back?</h3>
              </div>

              <div className="col-12">
                <div className="row">
                  {summaryFields.contactMethods.map((contactMethod) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={contactMethod.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updateContactMethods(contactMethod.title);
                              }}
                              type="checkbox"
                              name="contactMethods"
                              value={contactMethod.value}
                              defaultChecked={contactMethod.selected}
                            />
                            {contactMethod.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {showErrors && !mandatoryFields.contactMethods && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Contact method is required
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-5">
              <div className="col-12 mb-3">
                <h5>Preferred time</h5>
              </div>

              <div className="col-12">
                <div className="row">
                  {summaryFields.contactTimes.map((contactTime) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={contactTime.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updateContactTimes(contactTime.title);
                              }}
                              type="checkbox"
                              name="contactTimes"
                              value={contactTime.value}
                              defaultChecked={contactTime.selected}
                            />
                            {contactTime.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {showErrors && !mandatoryFields.contactTimes && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Contact time is required
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-5">
              <div className="col-12 mb-3">
                <h5>Preferred day</h5>
              </div>

              <div className="col-12">
                <div className="row">
                  {summaryFields.contactDays.map((contactDay) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={contactDay.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updateContactDays(contactDay.title);
                              }}
                              type="checkbox"
                              name="contactDays"
                              value={contactDay.value}
                              defaultChecked={contactDay.selected}
                            />
                            {contactDay.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {showErrors && !mandatoryFields.contactDays && (
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    <MdWarning className="mi-18" /> Contact day is required
                  </div>
                </div>
              )}
            </div>

            <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
              <div className="col-12 my-2">
                <div className="p">
                  Once you have selected your preferred contact method, time and day, please check the information you have entered is correct then simply click "Build My Loyalty Offer". Peter Cooper Volkswagen will come back to you with full details of our Unique Loyalty Offer prepared exclusively
                  for you.
                </div>
              </div>
            </div>

            {showErrors && (
              <div className="row text-center text-md-left mt-5 ">
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    <MdWarning className="mi-18" /> You must complete all mandatory fields above to continue
                  </div>
                </div>
              </div>
            )}

            <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-1">
              <div className="col-12">
                <div className={isLoading ? "btn btn-primary disabled" : "btn btn-primary"} onClick={() => navigateNext("confirmation")}>
                  Build my loyalty offer {isLoading && <Spinner animation="border" size="sm" className="ml-4" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
