import React from "react";
import { MdCopyright } from "react-icons/md";
import { useEffect, useRef } from "react";

const Footer = () => {
  const dateRef = useRef(null);

  // Replaces the footer yeat value on initial load
  useEffect(() => {
    console.log(dateRef.current.textContent);
    const date = new Date();
    const year = date.getFullYear();
    dateRef.current.textContent = year;
  }, []);

  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row align-items-">
            <div className="col-12 align-items-baseline">
              <MdCopyright className="align-middle" />
              <span ref={dateRef}>--Date--</span> Finance Loyalty Programme - All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
