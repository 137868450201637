import React from "react";

export const DataContext = React.createContext({
  welcome: {},
  selection: {
    preferences: [
      { title: "New", value: "New", selected: false },
      { title: "Pre-Reg", value: "Pre-Reg", selected: false },
      { title: "Ex-Demo", value: "Ex-Demo", selected: false },
      { title: "Used", value: "Used", selected: false },
    ],
    models: [
      {
        title: "up! 3 door",
        image: "up-3door.png",
        selected: false,
      },
      { title: "up! 5 door", image: "up-5door.png", selected: false },
      { title: "e-up!", image: "e-up.png", selected: false },
      { title: "Polo", image: "polo.png", selected: false },
      { title: "Golf 8", image: "golf-8.png", selected: false },
      { title: "Golf Estate 8", image: "golf-estate-8.png", selected: false },
      // { title: "Golf Estate", image: "golf-estate.png", selected: false },
      // { title: "e-Golf", image: "e-golf.png", selected: false },
      { title: "ID.3", image: "id3.png", selected: false },
      { title: "ID.4", image: "id4.png", selected: false },
      { title: "T-Cross", image: "t-cross.png", selected: false },
      { title: "T-Roc", image: "t-roc.png", selected: false },
      { title: "New T-Roc Cabriolet", image: "new-troc-cabriolet.png", selected: false },
      { title: "New Tiguan", image: "new-tiguan.png", selected: false },
      { title: "Tiguan Allspace", image: "tiguan-allspace.png", selected: false },
      { title: "Touareg", image: "touareg.png", selected: false },
      { title: "Passat Saloon", image: "passat-saloon.png", selected: false },
      { title: "Passat Estate", image: "passat-estate.png", selected: false },
      { title: "New Arteon", image: "new-arteon.png", selected: false },
      { title: "Arteon Shooting Brake", image: "arteon-shooting-brake.png", selected: false },
      { title: "Arteon", image: "arteon.png", selected: false },
      { title: "Touran", image: "touran.png", selected: false },
      { title: "Sharan", image: "sharan.png", selected: false },
    ],
  },
  finance: {
    budgets: [
      { title: "£99 - £149", value: "£99 - £149", selected: false },
      { title: "£150 - £199", value: "£150 - £199", selected: false },
      { title: "£200 - £249", value: "£200 - £249", selected: false },
      { title: "£250 - £299", value: "£250 - £299", selected: false },
      { title: "£300 - £349", value: "£300 - £349", selected: false },
      { title: "£350 - £399", value: "£350 - £399", selected: false },
      { title: "£400 - £449", value: "£400 - £449", selected: false },
    ],
    mileages: [
      { title: "5,000", value: "5,000", selected: false },
      { title: "10,000", value: "10,000", selected: false },
      { title: "15,000", value: "15,000", selected: false },
      { title: "20,000", value: "20,000", selected: false },
      { title: "25,000", value: "25,000", selected: false },
      { title: "30,000", value: "30,000", selected: false },
    ],
    deposit: "",
  },
  details: {
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobile: "",
    phone: "",
    finance: "",
    branchTradingName: "",
    branchAddress1: "",
    branchAddress2: "",
    branchAddress3: "",
    branchPostcode: "",
    branchTelephone: "",
    branchContactEmail: "",
    branchToEmail: "",
    branchFromEmail: "",
  },
  partExchange: {
    make: "",
    model: "",
    registration: "",
    mileage: "",
    transmission: "",
    fuel: "",
    colour: "",
    contractStartDate: "",
    contractEndDate: "",
    monthlyPayment: "",
    conditions: [
      { title: "Good", value: "Good", selected: false },
      { title: "Average", value: "Average", selected: false },
      { title: "Poor", value: "Poor", selected: false },
    ],
    currentMileage: "",
    comments: "",
  },
  summary: {
    contactMethods: [
      { title: "Email", value: "Email", selected: false },
      { title: "Sms", value: "Sms", selected: false },
      { title: "Telephone", value: "Telephone", selected: false },
    ],
    contactTimes: [
      { title: "Morning", value: "Morning", selected: false },
      { title: "Afternoon", value: "Afternoon", selected: false },
      { title: "Evening", value: "Evening", selected: false },
    ],
    contactDays: [
      { title: "Monday", value: "Monday", selected: false },
      { title: "Tuesday", value: "Tuesday", selected: false },
      { title: "Wednesday", value: "Wednesday", selected: false },
      { title: "Thursday", value: "Thursday", selected: false },
      { title: "Friday", value: "Friday", selected: false },
      { title: "Saturday", value: "Saturday", selected: false },
      { title: "Sunday", value: "Sunday", selected: false },
    ],
  },
  confirmation: {},
});
