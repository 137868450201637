import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";
import { MdWarning } from "react-icons/md";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";

const PartExchange = () => {
  let history = useHistory();
  const {
    state: {
      data: { details, partExchange },
    },
    dispatch,
  } = useContext(Context);

  const [detailsFields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);
  const [partExchangeFields, setPartExchangeFields] = useState(JSON.parse(localStorage.getItem("partExchange")) || partExchange);
  const [mandatoryFields, setMandatoryFields] = useState({ condition: false, currentMileage: false });
  const [showErrors, setShowErrors] = useState(false);

  const { make, model, registration, mileage, transmission, fuel, colour, currentMileage, comments, contractStartDate, contractEndDate, monthlyPayment } = partExchangeFields;

  const navigateNext = (slug) => {
    const { condition, currentMileage } = mandatoryFields;
    if (condition && currentMileage) {
      // Set the layout to completed and then
      dispatch({ type: "SET_LAYOUT_VISITED", slug: "part-exchange" });
      history.push(slug);
    } else {
      //set page error  to true
      setShowErrors(true);
    }
  };

  const navigateBack = (slug) => {
    // First set the layout to completed and then
    // dispatch({ type: "SET_LAYOUT_VISITED", slug: "welcome" });
    history.push(slug);
  };

  const updateConditions = (title) => {
    dispatch({ type: "UPDATE_PARTEXCHANGE_CONDITION", title: title });
  };

  const updateCurrentMileage = (event) => {
    dispatch({ type: "UPDATE_PARTEXCHANGE_MILEAGE", data: event.target.value });
  };

  const updateComments = (event) => {
    dispatch({ type: "UPDATE_PARTEXCHANGE_COMMENTS", data: event.target.value });
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const details = localStorage.getItem("details");
    const partExchange = localStorage.getItem("partExchange");

    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    if (partExchange) {
      dispatch({ type: "UPDATE_ALL_PARTEXCHANGE", data: JSON.parse(partExchange) });
      setPartExchangeFields(partExchange);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    const { condition, currentMileage } = mandatoryFields;

    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));

    setPartExchangeFields(partExchange);
    localStorage.setItem("partExchange", JSON.stringify(partExchange));

    if (condition && currentMileage) {
      // Set the layout to completed and then
      setShowErrors(false);
    }
  }, [details, partExchange, mandatoryFields]);

  // This useEffect will check whether any of the fields has been selected and set the button visibility
  useEffect(() => {
    const conditionIsEntered = partExchangeFields.conditions.some((element) => {
      return element.selected === true;
    });
    const currentMileageIsEntered = () => {
      if (partExchangeFields.currentMileage.length === 0) {
        return false;
      } else {
        return true;
      }
    };

    setMandatoryFields((prevState) => {
      return { ...prevState, condition: conditionIsEntered, currentMileage: currentMileageIsEntered() };
    });
  }, [partExchangeFields]);

  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">My current vehicle details</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">
            <img className="img-fluid" alt="event logo" src={EventImage} />
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-3">
          <div className="col-12 col-lg-6">
            <h5>Here are the details of your current vehicle according to our records. Please let us know if anything has changed.</h5>
          </div>
          <div className="col-12">
            <div className="form-row">
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Make:</strong> <span>{make}</span>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Model:</strong> <span>{model}</span>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Registration:</strong> <span>{registration}</span>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Transmission:</strong> <span>{transmission}</span>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Fuel:</strong> <span>{fuel}</span>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Colour:</strong> <span>{colour}</span>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Finance contract start date:</strong> <span>{contractStartDate}</span>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Finance contract renewal date:</strong> <span>{contractEndDate}</span>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 col-lg-3">
                <div className="p">
                  <strong>Current monthly payment:</strong> <span> £{monthlyPayment}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
          <div className="col-12 mb-3">
            <h3 className="page-sub-title">My car condition</h3>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {partExchangeFields.conditions.map((condition) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={condition.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onClick={() => {
                                updateConditions(condition.title);
                              }}
                              type="radio"
                              name="condition"
                              value={condition.value}
                              defaultChecked={condition.selected}
                            />
                            {condition.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {showErrors && !mandatoryFields.condition && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Car condition is required
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
          <div className="col-12 mb-3">
            <h3 className="page-sub-title">What is your current car mileage?</h3>
          </div>

          <div className="col-12 col-lg-6 my-2">
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-12 col-sm-7 col-md-5 col-lg-6">
                <div className="input-mileage">
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={currentMileage}
                    name="currentMileage"
                    onChange={(event) => {
                      updateCurrentMileage(event);
                    }}
                  />
                  <div className="font-weight-500">miles</div>
                </div>
              </div>
              {showErrors && !mandatoryFields.currentMileage && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Current mileage is required
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
          <div className="col-12 col-sm-7 col-md-4 col-lg-3 my-2">
            <div className="form-group">
              <h5>Comments</h5>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={2}
                onChange={(event) => {
                  updateComments(event);
                }}
                defaultValue={comments}
              />
            </div>
          </div>
        </div>

        {showErrors && (
          <div className="row text-center text-md-left mt-5 mb-n5">
            <div className="col-12 col-lg-6">
              <div className="alert alert-danger" role="alert">
                <MdWarning className="mi-18" /> You must complete all mandatory fields above to continue
              </div>
            </div>
          </div>
        )}

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-6">
                <div className="btn btn-primary" onClick={() => navigateBack("finance")}>
                  Back
                </div>
              </div>
              <div className="col-6">
                <div className="btn btn-primary" onClick={() => navigateNext("details")}>
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartExchange;
