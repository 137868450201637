import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";

const Welcome = () => {
  let history = useHistory();
  const {
    state: {
      data: { details },
    },
    dispatch,
  } = useContext(Context);

  const [detailsFields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);

  const navigateNext = (slug) => {
    // First set the layout to completed and then
    dispatch({ type: "SET_LAYOUT_VISITED", slug: "welcome" });
    //history.location.pathname.replace(/\\|\//g, "").toLowerCase()
    history.push(slug);
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const details = localStorage.getItem("details");

    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));
  }, [details]);
  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">Welcome</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">
            <img className="img-fluid" alt="event logo" src={EventImage} />
          </div>
        </div>

        <div className="row text-center text-md-left mt-3">
          <div className="col-12">
            <h3 className="page-sub-title">
              Dear {detailsFields.firstName} {detailsFields.lastName},
            </h3>
          </div>
        </div>
        <div className="row text-center text-md-left justify-content-between mt-5">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <p>
                  Our Volkswagen Finance Loyalty Programme only allows prioritised customers from the Dealer Network to participate, customers whose finance agreement expires within the next {details.finance} months. Those priority customers are to be offered additional, financial support towards
                  exchanging their current vehicle for another Volkswagen before the end of 30<sup>th</sup> July 2021.
                </p>
                <p>
                  The process to secure your bespoke Volkswagen Finance Loyalty offer is very simple. Firstly browse the model pages, selecting your vehicle preference and model. Proceed to the finance page to choose your monthly budget and input your average car mileage. Check your current vehicle
                  details are correct, if not comment on it and we will amend the details. Give an honest appraisal of your vehicle's condition and enter the current mileage.
                </p>
              </div>
            </div>

            <div className="row d-none d-md-block mt-2">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="row">
                  <div className="col-12">
                    <div className="btn btn-primary" onClick={() => navigateNext("selection")}>
                      Next
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <p>
              Once all these steps have been taken we will provide you with a summary of all the details, please check and if OK, click ‘Build My Loyalty offer’ and we will send your bespoke Volkswagen Finance Loyalty offer to Peter Cooper Volkswagen, who will contact you at your requested time as a
              matter of urgency.
            </p>

            <h5>Check out the Volkswagen Finance Loyalty Programme benefits:</h5>

            <ul className="pl-3">
              <li className="text-primary">Select any car from the Volkswagen range</li> <li className="text-primary">Adjust your finance budget</li> <li className="text-primary">Exclusive access to Volkswagen Finance Loyalty offers</li>{" "}
              <li className="text-primary"> Additional deposit contributions</li>{" "}
              <li className="text-primary">
                Extra vehicle discount support* <br />
                <span class="small">( * Extra discount support could be connected to Deposit Contribution, only available on stock new cars sold and delivered by 30th July 2021 )</span>
              </li>{" "}
              <li className="text-primary"> Hundred’s of stock vehicles available, ready for delivery now!</li>
            </ul>
          </div>
        </div>

        <div className="row text-center text-md-left mt-5 d-md-none">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-12">
                <div className="btn btn-primary" onClick={() => navigateNext("selection")}>
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
